import '../../../../node_modules/clipboard/dist/clipboard';
import {snackbar} from 'material-components-web';

const Clipboard = require('clipboard');

export default function () {
    // eslint-disable-next-line no-unused-vars
    const clipboard = new Clipboard('.copy-to-clipboard');
    clipboard.on('success', () => {
        const snackbarContainer = document.querySelector('.js-table-clipboard-snackbar-container');
        const barElement = snackbarContainer.querySelector('.mdc-snackbar');
        const bar = new snackbar.MDCSnackbar(barElement);
        bar.open();
    });
}
