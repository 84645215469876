import ajaxService from './services/ajaxService';

export default function collectionApi() {
    const elements = document.querySelectorAll('.js-collection-api');
    elements.forEach((container) => {
        let {endpoint} = container.dataset;
        const trigger = container.querySelector('.js-collection-api-trigger');
        const textfieldInput = container.querySelector('.js-collection-api-textfield input');

        trigger.addEventListener('click', () => {
            endpoint += textfieldInput.value;
            if (window.location.protocol === 'https:') {
                endpoint = endpoint.replace('http', 'https');
            }

            if (endpoint) {
                ajaxService.get(endpoint).then(() => {
                    window.location.reload();
                });
            }
        });
    });
}
