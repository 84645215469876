export default function () {
    const elements = document.querySelectorAll('.mdc-data-table');
    elements.forEach((element) => {
        const paginationButtons = element.querySelectorAll('.js-pagination-button');
        paginationButtons.forEach((button) => {
            if (typeof button.dataset.page !== 'undefined') {
                button.addEventListener('click', () => {
                    window.location = button.dataset.page;
                });
            }
        });
    });
}
