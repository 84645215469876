export default function fileUpload() {
    const elements = document.querySelectorAll('.js-file-upload');
    elements.forEach((container) => {
        const input = container.querySelector('.js-file-upload-input');
        const link = container.querySelector('.js-file-upload-link');
        const message = container.querySelector('.js-file-upload-message');
        const headline = container.querySelector('.js-file-upload-headline');

        let isSelected = false;

        input.addEventListener('change', () => {
            isSelected = (input.value !== '');

            const startIndex = (
                input.value.indexOf('\\') >= 0 ? input.value.lastIndexOf('\\') : input.value.lastIndexOf('/')
            );
            let filename = input.value.substring(startIndex);
            if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
                filename = filename.substring(1);
            }

            if (isSelected) {
                message.innerHTML = `${filename} ${message.dataset.messageSelected}`;
                link.innerHTML = link.dataset.messageSelected;
                headline.innerHTML = headline.dataset.messageSelected;

                container.classList.add('selected');
            } else {
                message.innerHTML = message.dataset.messageReset;
                link.innerHTML = link.dataset.messageReset;
                headline.innerHTML = headline.dataset.messageReset;

                container.classList.remove('selected');
            }
        });
    });
}
