import {tabBar} from 'material-components-web';

export default function () {
    const elements = document.querySelectorAll('.mdc-tab-bar');
    elements.forEach((element) => {
        const target = new tabBar.MDCTabBar(element);
        const contentElements = element.querySelectorAll('.js-tabbar-content');
        contentElements[0].classList.add('active');
        target.listen('MDCTabBar:activated', (event) => {
            const selectedIndex = event.detail.index;
            contentElements.forEach((content) => {
                if (parseInt(content.dataset.index, 10) === selectedIndex) {
                    content.classList.add('active');
                } else {
                    content.classList.remove('active');
                }
            });
        });
    });
}
