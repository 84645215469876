const $ = require('jquery');
require('jquery-pjax');

export default function pjax() {
    $('#generate-qrcode-form-pjax-container input, #generate-qrcode-form-pjax-container select').on('change', () => {
        if (window.isPjaxBusy) {
            return;
        }

        $.pjax({
            type: 'POST',
            url: $(this).href,
            container: '#generate-qrcode-form-pjax-container',
            fragment: '#generate-qrcode-form-pjax-container',
            data: $('#generate-qrcode-form-pjax-container form').serialize(),
            scrollTo: false,
        });
    });

    $('#generate-link-form-pjax-container input, #generate-link-form-pjax-container select').on('change', (event) => {
        if (window.isPjaxBusy) {
            return;
        }

        if (
            event.target.classList.contains('js-theme-dates-selects-day')
            || event.target.classList.contains('js-theme-dates-selects-month')
            || event.target.classList.contains('js-theme-dates-selects-year')
        ) {
            return;
        }
        $.pjax({
            type: 'POST',
            url: $(this).href,
            container: '#generate-link-form-pjax-container',
            fragment: '#generate-link-form-pjax-container',
            data: $('#generate-link-form-pjax-container form').serialize(),
            scrollTo: false,
        });
    });

    $('#mediaplan-form-pjax-container input, #mediaplan-form-pjax-container select').on('change', (event) => {
        if (window.isPjaxBusy) {
            return;
        }

        if (
            event.target.classList.contains('js-theme-dates-selects-day')
            || event.target.classList.contains('js-theme-dates-selects-month')
            || event.target.classList.contains('js-theme-dates-selects-year')
            || event.target.classList.contains('js-file-upload-input')
        ) {
            return;
        }
        $.pjax({
            type: 'POST',
            url: $(this).href,
            container: '#mediaplan-form-pjax-container-fragment',
            fragment: '#mediaplan-form-pjax-container-fragment',
            data: $('#mediaplan-form-pjax-container form').serialize(),
            scrollTo: false,
        });
    });
}
