export default function () {
    const elements = document.querySelectorAll('.js-accordion');

    elements.forEach((element) => {
        const trigger = element.querySelector('.js-accordion-trigger');
        if (trigger) {
            let isShown = false;
            trigger.addEventListener('click', (event) => {
                event.preventDefault();
                if (isShown) {
                    element.classList.remove('active');
                    isShown = false;
                } else {
                    element.classList.add('active');
                    isShown = true;
                }
            });
        }
    });
}
