import {select} from 'material-components-web';

export default function () {
    const elements = document.querySelectorAll('.mdc-select');
    elements.forEach((element) => {
        const target = new select.MDCSelect(element);
        const selectElement = element.querySelector('.js-select');
        const selectedIndexes = [];

        let selectIsMultiple = false;
        const labelElement = element.querySelector('.mdc-select__selected-text');

        if (selectElement) {
            selectIsMultiple = (selectElement.getAttribute('multiple') === 'multiple');

            if (selectIsMultiple) {
                for (let i = 0; i < selectElement.options.length; i += 1) {
                    const option = selectElement.options[i];
                    const item = target.menu.items[i];

                    if (option.selected === true) {
                        item.classList.add('selected');
                        selectedIndexes.push(option.value);
                    }
                }
            }

            const option = selectElement.options[selectElement.selectedIndex];
            if (option && option.dataset.selected === 'selected') {
                target.selectedIndex = selectElement.selectedIndex;
            }
        }

        const listItems = element.querySelectorAll('.mdc-list-item');
        listItems.forEach((listItem) => {
            listItem.addEventListener('click', (event) => {
                if (selectIsMultiple) {
                    if ((selectedIndexes.length + 1) > 0) {
                        target.getDefaultFoundation().setSelectedIndex(0);
                    }

                    if ((selectedIndexes.length - 1) === 0) {
                        target.getDefaultFoundation().setSelectedIndex(-1);
                    }

                    event.stopPropagation();

                    for (let i = 0; i < selectElement.options.length; i += 1) {
                        const option = selectElement.options[i];
                        const item = target.menu.items[i];

                        if (option.value === event.target.dataset.value) {
                            if (selectedIndexes.includes(option.value)) {
                                const index = selectedIndexes.indexOf(option.value);

                                if ((index === 0 && selectedIndexes.length > 0)
                                    || (index > 0 && selectedIndexes.length > 0)
                                ) {
                                    option.selected = false;
                                    item.classList.remove('selected');
                                    selectedIndexes.splice(index, 1);
                                }
                            } else if (!selectedIndexes.includes(option.value)) {
                                option.selected = 'selected';
                                item.classList.add('selected');
                                selectedIndexes.push(option.value);
                            }
                        }
                    }

                    if (selectedIndexes.length > 0) {
                        labelElement.textContent = target.menu.items.filter(
                            item => item.dataset.value === selectedIndexes[0],
                        )[0].textContent;
                        if ((selectedIndexes.length - 1) > 0) {
                            labelElement.textContent += ` +${selectedIndexes.length - 1}`;
                        }
                    }
                }
            });
        });

        target.listen('MDCSelect:change', (event) => {
            if (!selectIsMultiple) {
                if (selectElement) {
                    selectElement.selectedIndex = event.detail.index;
                    selectElement.dispatchEvent(new Event('change'));
                }

                if (event.target.dataset.openpage === '1') {
                    window.location = target.value;
                }
            }
        });

        if (selectedIndexes.length > 0 && selectIsMultiple) {
            labelElement.textContent = target.menu.items.filter(
                item => item.dataset.value === selectedIndexes[0],
            )[0].textContent;
            if ((selectedIndexes.length - 1) > 0) {
                labelElement.textContent += ` +${selectedIndexes.length - 1}`;
            }
        }
    });
}
