/* global ActiveXObject */
class AjaxService {
    /**
     * AJAX Request
     * @param method {string} Methode zum Senden der Daten.
     * @param url {string} Die Ziel-Url
     * @param data {object} Object mit den zu sendenden Daten
     * @returns {Promise} Gibt eine Promise zurück die bei success ein Response Object beinhaltet.
     */
    static makeRequest(method = 'GET', url = '') {
        return new Promise((resolve, reject) => {
            if (!url.length) {
                reject(Error('No AjaxUrl specified'));
            }

            const xhr = window.XMLHttpRequest ? new XMLHttpRequest() : new ActiveXObject('Microsoft.XMLHTTP');

            xhr.open(method, url);
            xhr.onreadystatechange = () => {
                if (xhr.readyState > 3) {
                    let json;

                    try {
                        json = JSON.parse(xhr.responseText);
                    } catch (e) {
                        reject(e);
                    }

                    if (xhr.status === 200) {
                        resolve(json);
                    }

                    reject(xhr.statusText);
                }
            };

            xhr.send();

            return xhr;
        });
    }
}

// eslint-disable-next-line no-unused-vars
const ajaxService = {
    post(url) {
        return AjaxService.makeRequest('POST', url);
    },
    get(url) {
        return AjaxService.makeRequest('GET', url);
    },
};

export default ajaxService;
