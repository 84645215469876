import {snackbar, switchControl} from 'material-components-web';
import ajaxService from '../services/ajaxService';

export default function () {
    const elements = document.querySelectorAll('.mdc-switch');
    elements.forEach((element) => {
        const target = new switchControl.MDCSwitch(element);
        target.listen('change', (event) => {
            if (typeof element.dataset.rowActiveSwitch !== 'undefined') {
                target.getDefaultFoundation().setDisabled(true);

                const {srcElement} = event;

                const isChecked = srcElement.checked;

                let request = srcElement.dataset.requestActive;
                if (isChecked) {
                    request = srcElement.dataset.requestInactive;
                }

                if (window.location.protocol === 'https:') {
                    request = request.replace('http', 'https');
                }

                if (request) {
                    ajaxService.get(request).then(() => {
                        target.getDefaultFoundation().setDisabled(false);
                    }).catch(() => {
                        target.getDefaultFoundation().setDisabled(false);
                        target.getDefaultFoundation().setChecked(!isChecked);
                        const snackbarContainer = document.querySelector('.js-table-edit-snackbar-container');
                        const barElement = snackbarContainer.querySelector('.mdc-snackbar');
                        const bar = new snackbar.MDCSnackbar(barElement);
                        bar.open();
                    });
                }
            }
        });
    });
}
