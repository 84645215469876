import {snackbar} from 'material-components-web';

export default function () {
    const elements = document.querySelectorAll('.mdc-snackbar');
    if (!elements) return;

    elements.forEach((element) => {
        const target = new snackbar.MDCSnackbar(element);
        target.timeoutMs = -1;
        if (typeof element.dataset.notOpen === 'undefined') {
            target.open();
        }
    });
}
