export default function () {
    const switches = document.querySelectorAll('.js-filter-switch');
    switches.forEach((element) => {
        const toggles = element.querySelectorAll('.js-filter-switch-toggle');
        toggles.forEach((toggle) => {
            toggle.addEventListener('click', (event) => {
                event.preventDefault();

                const {url, active} = toggle.dataset;

                element.classList.add((active === '1') ? 'active' : 'inactive');
                element.classList.remove((active === '1') ? 'inactive' : 'active');

                if (url) {
                    window.location = url;
                }
            });
        });
    });
}
