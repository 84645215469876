import select from './modules/mdc/select';
import switchControl from './modules/mdc/switch';
import textField from './modules/mdc/textfield';
import table from './modules/mdc/table';
import pjax from './modules/jquery/pjax';
import autocomplete from './modules/jquery/autocomplete';
import clipboard from './modules/jquery/clipboard';
import filterSwitch from './modules/filterSwitch';
import snackbar from './modules/mdc/snackbar';
import tabBar from './modules/mdc/tabBar';
import accordion from './modules/accordion';
import fileUpload from './modules/fileUpload';
import collectionApi from './modules/collectionApi';

const $ = require('jquery');

// Keep global state of pjax known.
window.isPjaxBusy = false;

function initAll() {
    $('.checkbox-select-all').click(() => {
        $($(this).data('checkbox-select-all-selector')).prop('checked', $(this).prop('checked'));
    });

    autocomplete();
    clipboard();
    pjax();
    select();
    switchControl();
    textField();
    table();
    filterSwitch();
    tabBar();
    accordion();
    fileUpload();
    snackbar();
    collectionApi();
}

$(document).ready(() => {
    initAll();

    $(document).on('pjax:send', () => {
        window.isPjaxBusy = true;
    });

    $(document).on('pjax:timeout', () => {
        window.isPjaxBusy = false;
    });

    $(document).on('pjax:complete', () => {
        initAll();
        window.isPjaxBusy = false;
    });
});
